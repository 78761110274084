<template>
  <div
    style="display: grid; place-items: center; height: 60vh; max-width: 100vw"
  >
    <span v-if="activeScreen === 1" style="font-size: 18px; margin-top: 40px">
      Escolha como recolher assinatura:
      {{ activeScreen }}
    </span>
    <div class="btn_menu" v-if="activeScreen === 1">
      <div class="menu_btn_container">
        <span style="margin-bottom: 15px">Fotografar O.S</span>
        <el-button
          type="warning"
          icon="el-icon-camera"
          class="icon_button"
          @click="goToTakePhotoPage()"
        />
      </div>
      <div class="menu_btn_container" style="margin-left: 15px">
        <span style="margin-bottom: 15px">Assinar via App</span>
        <el-button
          type="success"
          icon="el-icon-thumb"
          @click="activeScreen = 2"
          class="icon_button"
        />
      </div>
    </div>

    <div v-else-if="activeScreen === 2">
      <el-row>
        <div style="display: flex; justify-content: space-between">
          <span>Assinatura:</span>
          <el-radio-group size="mini" v-model="activeStep">
            <el-radio-button :label="1"> Cliente </el-radio-button>
            <el-radio-button :label="2"> Motorista </el-radio-button>
          </el-radio-group>
        </div>
      </el-row>
      <el-row>
        <VueSignaturePad
          id="signature"
          width="92vw"
          height="150px"
          ref="signaturePad"
          scaleToDevicePixelRatio
        />
        <v-ons-checkbox input-id="mail" value="true" v-model="mail">
          Enviar e-mail com a OS assinada
        </v-ons-checkbox>
        <div class="buttons">
          <el-button size="medium" type="primary" @click="clear">
            Limpar
          </el-button>
          <el-button size="medium" type="success" @click="save">
            Salvar
          </el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import OrderServicesServices from "@/services/OrderServices/order-services.services";
import { mapGetters } from "vuex";

export default {
  name: "FinishCollectPage",
  computed: {
    ...mapGetters("geolocation", ["getLocation"]),
  },
  data() {
    return {
      activeStep: 1,
      activeScreen: 1,
      mail: true,
    };
  },

  methods: {
    goToTakePhotoPage() {
      this.$router.push({
        name: "TakeOsPhotoPage",
      });
    },

    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      const byteArrays = [];

      for (let offset = 0; offset < b64Data.length; offset += sliceSize) {
        const slice = b64Data.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty)
        return this.$message({
          type: "error",
          message: "Assine antes de prosseguir!",
        });
      console.log("save");
      const loading = this.$loading(this.$options.loading);
      const signatureKey = this.getSignatureTypeKeyByStep(this.activeStep);
      const ordersIds = this.$store.state.ordersIds;
      const formData = new FormData();

      const blob = this.b64toBlob(data, "base64");
      formData.append("signature", blob, "close_os_signature");
      formData.append("key", signatureKey);
      formData.append("orders_ids", ordersIds);
      formData.append("mail", this.mail);
      formData.append("id", this.id);
      formData.append("lat", this.getLocation.lat);
      formData.append("lng", this.getLocation.lng);

      console.log("uploadSignature", formData);

      OrderServicesServices.uploadSignature(formData)
        .then((res) => {
          console.log("setTotalObjectsCollected", res.data);
          this.$message({
            type: "success",
            message: res.message,
          });

          this.activeStep++;
          this.clear();

          if (this.activeStep > 2) {
            this.$store.commit("clearBeepObjects");
            this.$store.commit("clearOrdersIds");
            this.$router.push({ name: "Home" });
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    getSignatureTypeKeyByStep(step) {
      console.log(step);
      const options = {
        1: "CLIENT",
        2: "DRIVER",
      };

      return options[step];
    },
  },
  mounted() {
    const loading = this.$loading(this.$options.loading);
    loading.close();
    console.log("id", this.$store.state.pendencyId);
    this.id = this.$store.state.pendencyId;
  },
};
</script>
<style scoped>
#signature {
  border-bottom: double 2px #1e1e1e;
  margin-bottom: 25px;
}
.icon_button {
  width: 123px;
  height: 50px;
  font-size: 28px;
}

.menu_btn_container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  align-items: center;
}
.btn_menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
}

.steps {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 50px;
}
.title_steps {
  margin-bottom: 15px;
  font-size: 20px;
  align-self: center;
}
</style>
