<template>
  <div>
    <div v-if="activeScreen === 1" class="first_step_container">
      <el-row>
        <div class="first_step_header">
          <span class="first_step_header_title">Local da entrega:</span>
          <el-radio-group
            v-model="deliveryPlace"
            style="margin-top: 10px; display: flex"
          >
            <el-radio
              style="margin: 0"
              border
              size="mini"
              :label="'DELIVERY_CORREIOS'"
            >
              Ag. Correios
            </el-radio>
            <el-radio border size="mini" :label="'DELIVERY_CUSTOMER'">
              Ag. Mais Envios
            </el-radio>
          </el-radio-group>
        </div>
      </el-row>

      <div class="first_step_form">
        <el-row>
          <div>
            <span class="first_step_form_title">Nome do recebedor:</span>
            <el-input style="margin-top: 10px" v-model="receiverName" />
          </div>
        </el-row>
        <el-row>
          <div>
            <span class="first_step_form_title">E-mail do recebedor:</span>
            <el-input style="margin-top: 10px" v-model="receiverMail" />
          </div>
        </el-row>
        <el-button
          style="margin-top: 10px; align-self: flex-end"
          size="medium"
          type="primary"
          @click="nextScreen()"
        >
          Proximo
        </el-button>
      </div>
    </div>

    <!-- Assinaturas -->
    <div v-else>
      <div class="total_by_service_container">
        <span>Confirma entrega dos objetos a seguir?</span>
        <div class="servicesItemContainer">
          <el-tag
            class="serviceItemTag"
            v-for="item in countByServices"
            :key="item.name"
            type="info"
            style="padding: 2px 10px"
          >
            {{ item.service }}:
            {{ item.total | formatTotalByService }}
          </el-tag>
        </div>
      </div>
      <el-row>
        <span>Assinatura:</span>
        <div style="display: flex; justify-content: space-between">
          <el-radio-group size="mini" v-model="activeStep">
            <el-radio-button :label="1"> Transportadora </el-radio-button>
            <el-radio-button :label="2"> Motorista </el-radio-button>
          </el-radio-group>
        </div>
      </el-row>
      <el-row>
        <VueSignaturePad
          id="signature"
          width="92vw"
          height="100px"
          ref="signaturePad"
          scaleToDevicePixelRatio
        />
        <div class="buttons">
          <el-button size="medium" type="primary" @click="clear">
            Limpar
          </el-button>
          <el-button size="medium" type="success" @click="save">
            Salvar
          </el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import OrderServicesServices from "@/services/OrderServices/order-services.services";

export default {
  name: "DeliveryObjectsPage",
  computed: {
    ...mapGetters("geolocation", ["getLocation"]),
  },
  data() {
    return {
      deliveryOs: null,
      activeStep: 1,
      activeScreen: 1,
      receiverName: null,
      receiverMail: null,
      deliveryPlace: "DELIVERY_CORREIOS",
      receiver_signature: null,
      driver_signature: null,
      pendecy_id: null,
      countByServices: null,
    };
  },
  methods: {
    nextStep() {
      if (!this.receiverName) {
        return this.$message({
          type: "error",
          message: "Informe o nome do recebedor!",
        });
      }
      if (this.activeStep <= 2) this.activeStep++;
    },

    nextScreen() {
      if (!this.receiverName)
        return this.$message({
          type: "error",
          message: "Informe o nome do recebedor!",
        });
      this.activeScreen = 2;
    },

    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      const byteArrays = [];

      for (let offset = 0; offset < b64Data.length; offset += sliceSize) {
        const slice = b64Data.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty)
        return this.$message({
          type: "error",
          message: "Assine antes de prosseguir!",
        });

      switch (this.activeStep) {
        case 1:
          this.receiver_signature = data;
          this.clear();
          this.nextStep();
          break;
        case 2:
          this.driver_signature = data;
          break;
      }

      if (this.receiver_signature && this.driver_signature) this.sendData();
    },

    async getTotalByService() {
      OrderServicesServices.countTotalByService({
        delivery_os_id: this.deliveryOs.id,
      })
        .then((response) => {
          this.countByServices = null;
          this.countByServices = response;
        })
        .catch((error) => {
          this.$notify.error({
            title: "Erro",
            message: error,
          });
        });
    },

    async sendData() {
      const loading = this.$loading(this.$options.loading);
      const formData = new FormData();

      const receiverSignatureBlob = this.b64toBlob(
        this.receiver_signature,
        "base64"
      );

      const driverSignatureBlob = this.b64toBlob(
        this.driver_signature,
        "base64"
      );

      formData.append(
        "receiver_signature",
        receiverSignatureBlob,
        "receiver_signature"
      );
      formData.append(
        "driver_signature",
        driverSignatureBlob,
        "driver_signature"
      );
      formData.append("order_id", this.deliveryOs.id);
      formData.append("lat", this.getLocation.lat);
      formData.append("long", this.getLocation.lng);
      formData.append("event_type", this.deliveryPlace);
      formData.append("receiver_name", this.receiverName);
      formData.append("receiver_mail", this.receiverMail);
      formData.append("pendency_id", this.pendecy_id);

      console.log("deliveryObjects", formData);
      OrderServicesServices.deliveryObjects(formData)
        .then((res) => {
          console.log("setTotalObjectsCollected", res.data);
          this.$store.commit("setTotalObjectsCollected", res.data);
          this.$message({
            type: "success",
            message: res.message,
          });
          this.$store.commit("setDeliveryOs", null);
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    getSignatureTypeKeyByStep(step) {
      const options = {
        1: "CLIENT",
        2: "DRIVER",
      };

      return options[step];
    },
  },

  filters: {
    formatTotalByService: (data) => data.toString().padStart(2, "0"),
  },

  mounted() {
    console.log("delivery", this.$route.params.pendecy_id);
    this.deliveryOs = this.$store.state.deliveryOs;
    this.getTotalByService();
    this.pendecy_id = this.$route.params.pendecy_id;
  },
};
</script>
<style scoped>
#signature {
  border-bottom: double 2px #1e1e1e;
  margin-bottom: 25px;
}
.icon_button {
  width: 150px;
  height: 50px;
  font-size: 28px;
}

.menu_btn_container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  align-items: center;
}
.btn_menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
}

.steps {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 50px;
}
.title_steps {
  margin-bottom: 15px;
  font-size: 20px;
  align-self: center;
}

.first_step_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.first_step_header {
  margin-bottom: 6vh;
}

.first_step_header_title,
.first_step_form_title {
  font-size: 18px;
}

.first_step_form {
  display: flex;
  flex-direction: column;
}

.servicesItemContainer {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}
</style>
