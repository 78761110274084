import axios from "axios";
import { TokenService } from "./auth/storage.services";

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
    axios.defaults.headers.common["Content-Type"] =
      "application/json; charset=UTF-8";
  },
  removeHeader() {},

  get(resource) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .get(resource)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  filter(resource, params) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .get(resource, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  post(resource, data, headers) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .post(resource, data, { headers: headers })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  download(resource, data, headers) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .post(resource, data, { responseType: "blob", headers: headers })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("error", error, error.response);
          if (error && error.response && error.response.data) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
  },

  put(resource, data) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .put(resource, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  patch(resource, data) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .patch(resource, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },
  delete(resource) {
    return new Promise((resolve, reject) => {
      this.setHeader();
      axios
        .delete(resource)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
